import React, { Component } from "react";

// import PropTypes from 'prop-types';
export class TopNav extends Component {
  // static propTypes = {
  //     page: PropTypes.object.isRequired
  // }
  componentDidMount() {}
  render() {
    return (
      <div>
        <div
          id="TopNav"
          className="jumbotron d-flex align-items-center boon-gl level-1"
        >
          <div className="container text-center">
            <h1 id="TitleBox" className="display-1 boon-gl level-15">
              {process.env.REACT_APP_TITLE}
            </h1>
          </div>
          <div className="rectangle-2"></div>
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <div className="circle-3"></div>
          <div className="triangle triangle-1"></div>
          <div className="triangle triangle-2"></div>
          <div className="triangle triangle-3"></div>
          <div className="triangle triangle-4"></div>
        </div>
      </div>
    );
  }
}
export default TopNav;

import React, { Component } from "react";
import InfoGallery from "./InfoGallery";
import { Icon } from "@iconify/react";

export class InfoBoxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalId: "",
      modalIndex: -1,
      displayBoxes: [],
    };
  }

  handleShow = (index) => {
    this.setState({ modalIndex: index }, () => {
      //console.log("set modalIndex to", this.state.modalIndex, index);
      document.getElementById("check-" + this.state.modalId).checked = false;
      document.getElementById(this.state.modalId)?.showModal();
    });
  };

  handleNext = () => {
    if (this.state.modalIndex < this.state.displayBoxes.length - 1) {
      this.setState((prevState) => ({
        modalIndex: prevState.modalIndex + 1,
      }));
    }
  };

  handlePrevious = () => {
    if (this.state.modalIndex > 0) {
      this.setState((prevState) => ({
        modalIndex: prevState.modalIndex - 1,
      }));
    }
  };

  modalBox = () => {
    return this.state.modalIndex < this.state.displayBoxes.length
      ? this.state.displayBoxes[this.state.modalIndex]
      : null;
  };

  componentDidMount() {
    this.setState({
      modalId: "info" + this.props.category,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.boxes.length > 0 && prevProps.boxes.length === 0) {
      this.setState({
        displayBoxes: this.props.boxes
          .filter((box) => box.acf.category === this.props.category)
          .sort(
            (a, b) => parseInt(a.acf.order, 10) - parseInt(b.acf.order, 10)
          ),
      });
    }
  }

  render() {
    const { category, content } = this.props;
    const { modalIndex, modalId, displayBoxes } = this.state;

    return (
      <div
        id={"info-" + category}
        aria-expanded="true"
        className="drawer drawer-end"
      >
        <input
          id={"check-" + modalId}
          type="checkbox"
          className="drawer-toggle"
        />
        <div className="drawer-content">
          {content}
          <dialog id={modalId} className="modal">
            <div className="modal-box">
              <div className="join">
                {displayBoxes.map((box, index) => (
                  <button
                    onClick={() => this.handleShow(index)}
                    className={
                      "join-item btn " +
                      (index === modalIndex ? "btn-active" : "")
                    }
                  >
                    {index + 1}
                  </button>
                ))}
              </div>

              <h3 className="font-bold text-lg">
                {this.modalBox()?.title?.rendered}
              </h3>
              <p
                className="py-4"
                dangerouslySetInnerHTML={{
                  __html: this.modalBox()?.content?.rendered,
                }}
              />
              <InfoGallery gallery={this.modalBox()?.acf?.gallery} />
              <div className="modal-action">
                <button
                  className="btn"
                  onClick={this.handlePrevious}
                  disabled={modalIndex <= 0}
                >
                  <Icon icon="ooui:next-rtl" className="next-box" /> Previous
                </button>

                <button
                  className="btn"
                  onClick={this.handleNext}
                  disabled={modalIndex >= displayBoxes.length - 1}
                >
                  Next <Icon icon="ooui:next-ltr" className="prev-box" />
                </button>

                <form method="dialog">
                  <button className="btn">Close</button>
                </form>
              </div>
            </div>
          </dialog>
          <button className="info-loading btn">
            <span className="loading loading-spinner"></span>
            loading
          </button>
          <label
            htmlFor={"check-" + modalId}
            className="info-toggle drawer-button btn"
          >
            <span className="loading loading-ring loading-lg"></span>
            {category.replace(/_/g, " ")}
          </label>
        </div>
        <div className="drawer-side">
          <ul className="menu bg-base-200 text-base-content min-h-full w-80 p-4">
            <label htmlFor={"check-" + modalId} className="drawer-button btn">
              {category.replace(/_/g, " ")}
              <Icon
                icon="mingcute:arrow-to-right-line"
                width="1.2rem"
                height="1.2rem"
                style={{ color: "#fff" }}
              />
            </label>
            {displayBoxes.map((box, index) => (
              <li key={box.id}>
                <a id={box.id} onClick={() => this.handleShow(index)}>
                  {box.title.rendered}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default InfoBoxes;

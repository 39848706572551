import { useEffect } from "react";
const useSetupUnity = (sendMessage, isLoaded, browserInfo) => {
  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    const GetId = (target) => {
      var t = target;
      while (t && !t.id) t = t.parentNode;
      if (t) {
        return t.id;
      }
    };
    const OnDivClick = (e) => {
      if (!isLoaded) return;
      var id = GetId(e.target);
      //console.log("JS OnDivClick",id);
      sendMessage("JSListener", "OnDivClick", id);
    };
    const OnDivMouseUp = (e) => {
      if (!isLoaded) return;
      var id = GetId(e.target);
      //console.log("JS OnDivMouseUp",id);
      sendMessage("JSListener", "OnDivMouseUp", id);
    };
    function OnDivOver(e) {
      if (!isLoaded) return;
      var id = GetId(e.target);
      //console.log("JS OnDivOver",e.target.id);
      sendMessage("JSListener", "OnDivOver", id);
    }
    function OnDivOut(e) {
      if (!isLoaded) return;
      var id = GetId(e.target);
      //console.log("JS OnDivOut",e);
      sendMessage("JSListener", "OnDivOut", id);
    }

    const collection = document.getElementsByClassName(
      process.env.REACT_APP_UNITYCLASS
    );
    sendMessage("JSListener", "OnBrowserInfo", JSON.stringify(browserInfo));
    for (let i = 0; i < collection.length; i++) {
      collection[i].addEventListener("click", OnDivClick);
      collection[i].addEventListener("mouseup", OnDivMouseUp);
      collection[i].addEventListener("mouseover", OnDivOver);
      collection[i].addEventListener("mouseout", OnDivOut);
      // console.log(
      //   "collection[" + i + "] " + collection[i].id + " has mouse events added"
      // );
    }

    return () => {
      const collection = document.getElementsByClassName(
        process.env.REACT_APP_UNITYCLASS
      );
      for (let i = 0; i < collection.length; i++) {
        collection[i].removeEventListener("click", OnDivClick);
        collection[i].removeEventListener("mouseup", OnDivMouseUp);
        collection[i].removeEventListener("mouseover", OnDivOver);
        collection[i].removeEventListener("mouseout", OnDivOut);
        // console.log("collection["+i+"] "+collection[i].id+" has mouse events added");
      }
    };
  }, [sendMessage, isLoaded, browserInfo]);
};
export default useSetupUnity;

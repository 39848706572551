import { UAParser } from "ua-parser-js";
import { useEffect, useState } from "react";
const useBrowserInfo = () => {
  const [browserInfo, SetBrowserInfo] = useState(null);

  useEffect(() => {
    const getBrowserInfo = () => {
      const canvas = document.createElement("canvas");
      const gl =
        canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
      const webglSupported = gl && gl instanceof WebGLRenderingContext;
      const browserInfo = {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        appName: navigator.appName,
        appVersion: navigator.appVersion,
        vendor: navigator.vendor,
        memory: navigator.deviceMemory,
        cpu: navigator.hardwareConcurrency,
        cpuSpeed: detectCPU(),
        webglParameters: webglSupported
          ? {
              webglVersion: gl.getParameter(gl.VERSION),
              webglRenderer: gl.getParameter(gl.RENDERER),
              webglVendor: gl.getParameter(gl.VENDOR),
              maxTextureSize: gl.getParameter(gl.MAX_TEXTURE_SIZE),
              maxVertexAttribs: gl.getParameter(gl.MAX_VERTEX_ATTRIBS),
              maxRenderBuffer: gl.getParameter(gl.MAX_RENDERBUFFER_SIZE),
              maxTextureImageUnits: gl.getParameter(
                gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS
              ),
              maxCubeMapTextureSize: gl.getParameter(
                gl.MAX_CUBE_MAP_TEXTURE_SIZE
              ),
              maxFragmentVectors: gl.getParameter(
                gl.MAX_FRAGMENT_UNIFORM_VECTORS
              ),
              maxVaryingVectors: gl.getParameter(gl.MAX_VARYING_VECTORS),
              maxVertexUniformVectors: gl.getParameter(
                gl.MAX_VERTEX_UNIFORM_VECTORS
              ),
              maxViewportDims: gl.getParameter(gl.MAX_VIEWPORT_DIMS)["0"],
            }
          : null,
      };
      //let str=JSON.stringify(browserInfo);
      canvas.remove();
      SetBrowserInfo(browserInfo);
      // document.getElementById("browserinfodiv").innerHTML=JSON.stringify(browserInfo);
    };
    function detectCPU() {
      const p = navigator.platform.toLowerCase();
      const parser = new UAParser(navigator.userAgent);
      const result = parser.getResult();

      let platform = p.concat(result.cpu.architecture);
      // Default CPU speed (in MHz) if unknown
      let cpuSpeed = 1000;
      if (platform.includes("iphone") || platform.includes("ipad")) {
        cpuSpeed = 3780;
      } else if (platform.includes("win64") || platform.includes("x64")) {
        cpuSpeed = 2500; // Typical modern Intel/AMD 64-bit desktop or laptop CPUs
      } else if (platform.includes("aarch64")) {
        cpuSpeed = 2200; // ARM64 CPUs (mobile devices, some laptops, Apple M1)
      } else if (
        platform.includes("armv7") ||
        platform.includes("arm") ||
        platform.includes("armv8")
      ) {
        cpuSpeed = 1100; // Older ARM32 CPUs, generally for mobile devices
      } else if (platform.includes("ppc") || platform.includes("powerpc")) {
        cpuSpeed = 1800; // PowerPC, older architecture, usually for legacy Macs
      } else if (platform.includes("mips") || platform.includes("mips64")) {
        cpuSpeed = 1200; // MIPS, typically used in embedded systems or routers
      } else if (platform.includes("sparc")) {
        cpuSpeed = 1500; // SPARC, usually found in older server systems
      } else if (platform.includes("riscv") || platform.includes("riscv64")) {
        cpuSpeed = 1800; // RISC-V, modern experimental or emerging hardware
      } else if (platform.includes("ia64")) {
        cpuSpeed = 1600; // Itanium, older server CPUs, lower speeds
      } else if (platform.includes("s390")) {
        cpuSpeed = 3000; // S390, IBM mainframe, often clocked very high
      } else if (platform.includes("macintosh") && platform.includes("arm")) {
        cpuSpeed = 3200; // Apple M1 and newer AR M-based Macs
      } else if (platform.includes("win32") || platform.includes("wow64")) {
        cpuSpeed = 1600; // 32-bit Intel, generally older systems
      }

      return cpuSpeed;
    }
    getBrowserInfo();
  }, []);
  return browserInfo;
};
export default useBrowserInfo;

import { useEffect } from "react";
const useUpdateUnity = (
  windowSize,
  scrollY,
  sendMessage,
  isLoaded,
  animationEvent
) => {
  useEffect(() => {
    if (!isLoaded) return;

    // console.log("animationEvent: ", animationEvent);
    sendMessage("JSListener", "OnResize", windowSize[0] + "," + windowSize[1]);
    const collection = document.getElementsByClassName(
      process.env.REACT_APP_UNITYCLASS
    );
    // console.log(
    //   "collection: ",
    //   process.env.REACT_APP_UNITYCLASS,
    //   collection.length
    // );
    let leftOffset = document
      .getElementById("rootBody")
      .getBoundingClientRect().left;

    for (let i = 0; i < collection.length; i++) {
      let update = {
        id: collection[i].id,
        className: collection[i].className,
        position: [
          collection[i].getBoundingClientRect().left - leftOffset,
          collection[i].getBoundingClientRect().top,
        ],
        size: [
          collection[i].getBoundingClientRect().width,
          collection[i].getBoundingClientRect().height,
        ],
        visible:
          collection[i].getBoundingClientRect().bottom > window.scrollY &&
          collection[i].getBoundingClientRect().top <
            window.scrollY + windowSize[1],
      };
      sendMessage("JSListener", "OnDivChange", JSON.stringify(update));
    }
    sendMessage("JSListener", "OnScroll", scrollY);
  }, [windowSize, scrollY, sendMessage, isLoaded, animationEvent]);
};
export default useUpdateUnity;

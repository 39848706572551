import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import Content from "./components/Content";
import "./index.css";
import useWindowSize from "./js/useWindowSize.js";
import useBrowserInfo from "./js/useBrowserInfo.js";
import useScroll from "./js/useScroll.js";
import useUnity from "./js/useUnity.js";
import useUpdateUnity from "./js/useUpdateUnity.js";
import useSetupUnity from "./js/useSetupUnity.js";
import useAnimationEvent from "./js/useAnimationEvent.js";
function App() {
  const windowSize = useWindowSize();
  const scrollPos = useScroll();
  const animationEvent = useAnimationEvent();
  const browserInfo = useBrowserInfo();
  const unityData = useUnity(useUnityContext);
  useSetupUnity(unityData.sendMessage, unityData.isLoaded, browserInfo);
  useUpdateUnity(
    windowSize,
    scrollPos,
    unityData.sendMessage,
    unityData.isLoaded,
    animationEvent
  );
  return (
    <div
      id="rootBody"
      className={"App " + (unityData?.isLoaded ? "WebGLLoaded" : "")}
    >
      {browserInfo?.webglParameters && unityData?.unityProvider ? (
        <Unity
          unityProvider={unityData?.unityProvider}
          style={{ visibility: unityData?.isLoaded ? "visible" : "hidden" }}
          matchWebGLToCanvasSize={true}
          width="100%"
          height="100%"
          devicePixelRatio={1}
        />
      ) : (
        "<span></span>"
      )}
      <Content />
    </div>
  );
}
export default App;

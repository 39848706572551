import { useEffect, useState } from "react";

const useUnity = (useUnityContext) => {
  const [unityInfo, setUnityInfo] = useState({
    unityProvider: null,
    sendMessage: null,
    addEventListener: null,
    removeEventListener: null,
    isLoaded: false,
  });

  const buildUrl = "/webgl/";
  const { unityProvider, sendMessage, addEventListener, removeEventListener } =
    useUnityContext({
      loaderUrl: buildUrl + "Build/webgl.loader.js",
      dataUrl: buildUrl + "Build/webgl.data.unityweb",
      frameworkUrl: buildUrl + "Build/webgl.framework.js.unityweb",
      codeUrl: buildUrl + "Build/webgl.wasm.unityweb",
    });
  if (!unityInfo.unityProvider && unityProvider && !unityInfo.isLoaded) {
    setUnityInfo({
      unityProvider,
      sendMessage,
      addEventListener,
      removeEventListener,
      isLoaded: false,
    });
  }
  useEffect(() => {
    // Only proceed if unityProvider is not null
    if (!unityProvider) return;
    const handleSetIsLoaded = (val) => {
      setUnityInfo({
        unityProvider,
        sendMessage,
        addEventListener,
        removeEventListener,
        isLoaded: true,
      });
    };
    addEventListener("SetIsLoaded", handleSetIsLoaded);
    // Clean up event listener on unmount
    return () => {
      removeEventListener("SetIsLoaded", handleSetIsLoaded);
    };
  }, [unityProvider, sendMessage, addEventListener, removeEventListener]);

  return unityInfo;
};
export default useUnity;

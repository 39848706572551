import React, { Component } from 'react'

export class InfoGallery extends Component {
    
    
    render() {
        const{gallery} =this.props;
        if(gallery==null||gallery?.length===0)return;
        let moreThanOne=gallery.length>1;    
        return  (       
            <div className="carousel w-full">
                {gallery.length}
            {gallery.map((item,index)=>(
             <div id={item.id} className="carousel-item relative w-full">
              <img
                alt={item.title}
                src={item.full_image_url}
                className="w-full" />
                {moreThanOne?(
              <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
                <a href={"#"+(gallery[index===0?gallery.length-1:index-1].id)} className="btn btn-circle">❮</a>
                <a href={"#"+(gallery[index===gallery.length-1?0:index+1].id)} className="btn btn-circle">❯</a>
              </div>
              ):(<></>)
            }
            </div>
                
            ))}
            
          </div>
        )
    }
}
export default InfoGallery
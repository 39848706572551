import { useEffect, useState } from "react";
const useAnimationEvent = () => {
  const [animationTime, setAnimation] = useState(0);

  useEffect(() => {
    const handleAnim = (e) => {
      setAnimation(Date.now());
    };
    window.addEventListener("animationend", handleAnim);
    return () => {
      window.removeEventListener("animationend", handleAnim);
    };
  }, []);
  return animationTime;
};
export default useAnimationEvent;
